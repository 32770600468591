import React, { useState, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { scroller, Element } from "react-scroll";
import Home from "./screens/Home";
import Bio from "./screens/Bio";
import "./assets/css/App.css";

function App() {
  const [showHome, setShowHome] = useState(true);
  const [showBio, setShowBio] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowBio(true);
      scrollingToanimate();
    }, 2000);
  }, []);

  const scrollingToanimate = () => {
    scroller.scrollTo("bio", {
      duration: 800,
      delay: 100,
      smooth: "easeIn",
    });

    setTimeout(() => {
      setShowHome(false);
    }, 2000);
  };

  return (
    <BrowserRouter>
      <div className="container">
        {showHome ? <Home /> : null}
        <Element name="bio">{showBio ? <Bio /> : null}</Element>
      </div>
    </BrowserRouter>
  );
}

export default App;
