import React from "react";
import styled from "styled-components";
import logoFinanzas from "../assets/img/logo.svg";
import ScrollAnimation from "react-animate-on-scroll";

const Section = styled.section``;

const Div = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
  }
`;

const Image = styled.img`
  width: 540px;
  height: auto;
  position: relative;
  margin: 0 auto;

  @media (max-width: 768px) {
    width: 220px;
    margin: 1.4rem;
  }
`;

function Home() {
  return (
    <Section>
      <Div active>
        <ScrollAnimation animateIn="animate__fadeInUp">
          <Image src={logoFinanzas} alt="finanzasLogo" />
        </ScrollAnimation>
      </Div>
    </Section>
  );
}

export default Home;
