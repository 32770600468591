import React from "react";
import styled from "styled-components";
import gsap from "gsap";
import logoFinanzas from "../assets/img/logo.svg";

import {
  FaInstagramSquare,
  FaLinkedin,
  FaTwitterSquare,
  FaFacebookSquare,
} from "react-icons/fa";
import Avatar from "../assets/img/avatar.svg";
import ScrollAnimation from "react-animate-on-scroll";

const Section = styled.section`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
`;

const Div = styled.div`
  margin: 0;
  padding: 1rem;
`;

const Info = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  @media (max-width: 768px) {
    grid-template-columns: auto;
  }
`;

const Article = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: 12px;
  width: 90%;

  @media (max-width: 768px) {
    padding-right: 0;
    width: 100%;
    padding: 12px;
  }
`;

const Logo = styled.img`
  width: 160px;
  margin-top: 1.4rem;
  height: auto;
  position: relative;
`;

const Paragraph = styled.p`
  text-align: right;
  line-height: 1.2;
  margin-top: 8px;
  margin-bottom: 8px;
  font-family: "Berlin Sans FB", "Lucida Sans", "Lucida Sans Regular",
    "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-size: 1.2rem;

  @media (max-width: 768px) {
    text-align: center;
    font-size: 1.2rem;
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  margin: 0 auto;
  @media (max-width: 768px) {
  }
`;

const Social = styled.div`
  width: 100%;
  height: auto;
  padding: 3rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .icons {
    color: #808080;
    margin-left: 4px;
    margin-right: 4px;
    font-size: 2rem;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    @media (max-width: 768px) {
      font-size: 1.4rem;
    }
  }
  .icons:hover {
    color: #5932ad;
    transform: scale(1.1);
  }
  .follow {
    color: #808080;
    font-size: 1.2rem;
    font-family: "Berlin Sans FB";
  }
`;

const Contacto = styled.div`
  .contact_btn {
    width: 320px;
    height: 90px;
    padding: 12px;
    color: #ffffff;
    background-color: #5932ad;
    border: none;
    font-size: 1.6rem;
    cursor: pointer;
    font-family: "Berlin Sans FB";

    @media (max-width: 768px) {
      width: 280px;
      height: 40px;
      padding: 12px 12px 2rem 12px;
      font-size: 1.4rem;
    }
  }

  .contact_btn:hover {
    background-color: #808080;
  }
`;

function Bio() {
  const onEnter = ({ currentTarget }) => {
    gsap.to(currentTarget, { backgroundColor: "#a1dbb1", scale: 1.2 });
  };

  const onLeave = ({ currentTarget }) => {
    gsap.to(currentTarget, { backgroundColor: "#5932ad", scale: 1 });
  };

  return (
    <Section>
      <Logo src={logoFinanzas} alt="finanzasLogo" />
      <Div>
        <Info>
          <Article>
            <ScrollAnimation animateIn="animate__fadeInLeft" delay={600}>
              <Paragraph>
                Explicamos de forma sencilla y simplificada conceptos
                financieros usando dibujitos y síntesis de información.
              </Paragraph>

              <Paragraph>
                La finalidad es contribuir a la educación financiera en México.
              </Paragraph>
            </ScrollAnimation>
          </Article>

          <ScrollAnimation animateIn="animate__slideInUp" delay={400}>
            <Image src={Avatar} alt="avatar" />
          </ScrollAnimation>

          <ScrollAnimation
            animateIn="animate__fadeInRight"
            delay={800}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Contacto>
              <button
                className="contact_btn"
                onMouseEnter={onEnter}
                onMouseLeave={onLeave}>
                CONTACTO
              </button>
            </Contacto>
          </ScrollAnimation>
        </Info>
        <Social>
          <p className="follow">Síguenos</p>
          <a
            href="https://www.facebook.com/finanzascondibujitos"
            target="_blank" rel="noopener noreferrer">
            <FaFacebookSquare className="icons" />
          </a>
          <a
            href="https://www.instagram.com/finanzascondibujitos"
            target="_blank" rel="noopener noreferrer">
            <FaInstagramSquare className="icons" />
          </a>
          <a href="https://twitter.com/FinanzasConD" target="_blank" rel="noopener noreferrer">
            <FaTwitterSquare className="icons" />
          </a>
          <a
            href="https://www.linkedin.com/company/finanzas-con-dibujitos/"
            target="_blank" rel="noopener noreferrer">
            <FaLinkedin className="icons" />
          </a>
        </Social>
      </Div>
    </Section>
  );
}

export default Bio;
